<template lang="pug">
  tr.group(v-if="title.length")
    td.group__title
      span {{ title }}
    td
    td
    td
    td
    td
    td
    td
    td
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
    },
  }
</script>

<style lang="scss" scoped>
  .group{
    background-color: #f5f5f5;

    &:hover{
      background-color: #f5f5f5 !important;
    }

    &__title{
      display: flex;
      align-items: center;
      position: absolute;
      padding-left: 16px;
      color: #4d7fed;
      height: 48px;
      font-size: 18px;
      width: calc(100% - 32px);
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      font-weight: 700;

      span{
        margin-left: 8px;
        margin-right: 16px;
      }
    }
    td{
      background-color: #f5f5f5;
    }
  }
</style>
